import React from "react"
import { Container, Row} from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
// Sowiński

import sow2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/SOWWebp/193659319.jpg';
import sow4 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/SOWWebp/193659359.jpg';
import sow5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/SOWWebp/193659378.jpg';
import sow6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/SOWWebp/193659386.jpg';
import sow7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/SOWWebp/193659416.jpg';
import sow8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/SOWWebp/SOW12.webp';
import sow9 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/SOWWebp/SOW14.webp';


// 4K
import naut1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/4K13.webp';
import naut2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/173995583.jpg';
import naut3 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/173995593.jpg';
import naut4 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/173995598.jpg';
import naut5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/173995606.jpg';
import naut6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/173995608.jpg';
import naut7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/173995613.jpg';
import naut8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/4Kwebp/173995614.jpg';


// // 5K
import nautica1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/5Kwebp/285971597-1.jpg';
import nautica3 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/5Kwebp/285971611.jpg';
import nautica5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/5Kwebp/285971630.jpg';
import nautica6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/5Kwebp/5k-11.webp';
import nautica7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/5Kwebp/5k15.jpg';
import nautica8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/5Kwebp/285977116.jpg';



// 8M 9M

import szarotki1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539056.jpg';
import szarotki2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539198.jpg';
import szarotki3 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539232.jpg';
import szarotki4 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539254.jpg';
import szarotki5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539334.jpg';
import szarotki6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539358.jpg';
import szarotki7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539522.jpg';
import szarotki8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/8Mwebp/501539533.jpg';

// Barbakan

import bar1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580559265.jpg'
import bar2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550035.jpg'
import bar3 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550125.jpg'
import bar4 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550132.jpg'
import bar4a from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550043.jpg'
import bar4b from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550145.jpg'
import bar5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550093.jpg'
import bar6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550139.jpg'
import bar7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580550101.jpg'
import bar8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/BAR/580549985.jpg'

// // Rewal

import rewal1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/311368973.jpg';
import rewal2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/311382467.jpg';
import rewal3 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313157326.jpg';
import rewal4 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313157328.jpg';
import rewal5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313159302.jpg';
import rewal6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313159303.jpg';
import rewal7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313159729.jpg';
import rewal8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313160412.jpg';
import rewal9 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313160413.jpg';
import rewal10 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313160414.jpg';
import rewal11 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313160415.jpg';
import rewal12 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/rewal11.webp';
import rewal13 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/REWALWebp/313161274.jpg';

// Rewal 2

import rew1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545950311.jpg'
import rew2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545951028.jpg'
import rew3 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545951211.jpg'
import rew4 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545951330.jpg'
import rew5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545951551.jpg'
import rew6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545951823.jpg'
import rew7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545960856.jpg'
import rew8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545665174.jpg'
import rew9 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545664850.jpg'
import rew10 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545665024.jpg'
import rew11 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545665042.jpg'
import rew12 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/6M/545665071.jpg'


// WROCŁAW 

import wro1 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993681.jpg'
import wro2 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993821.jpg'
import wro3 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993823.jpg'
import wro4 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993833.jpg'
import wro5 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993840.jpg'
import wro6 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993847.jpg'
import wro7 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993856.jpg'
import wro8 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993863.jpg'
import wro9 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993869.jpg'
import wro10 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993887.jpg'
import wro11 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993902.jpg'
import wro12 from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/zdj/10Wwroc/647993907.jpg'



export const Apartments = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

  return(
<section className="apartments" id="apartments">
    <Container> 
    <div className="apartments-szczecin" id='apartments-szczecin'>
        <h2>Apartamenty w Szczecinie</h2> 
        <h3> Rezerwacja poprzez Booking.com lub bezpośrednio (zniżka 5% od ceny z Booking.com)</h3>
        <h5>biuro@klepaccy.pl</h5>
        <h4>+48 601 76 26 07</h4>
        </div>
      <Container>

        <Row>
            <div className="img-szczecin">
                <div className="box">
                    <h3>Apartament 3D Sowińskiego</h3>
                    <p className="adress">Generała Józefa Sowińskiego 74/6, <br></br>Śródmieście, 70-236 Szczecin</p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● prywatny parking </p>
                    <p className="point"> ● WIFI </p>
                    <p className="pointa"> ● zwierzęta domowe są akceptowane </p>
                    <a href="https://www.booking.com/hotel/pl/apartament-3d-gen-jsowinskiego.pl.html?aid=318615&label=New_Polish_PL_PL_27026340625-8jnhBl9f%2ARWlwi1GFE5ASAS637942120544%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atidsa-140566009945%3Alp20862%3Ali%3Adec%3Adm%3Aag27026340625%3Acmp400536505&sid=cf797595966fbc21a6acb111e5a6dcba&dest_id=-531881;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1703695639;srpvid=d8b37607d6ed0009;type=total;ucfs=1&#hotelTmpl" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a>
                </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={sow6} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={sow2} alt="Item 1" /> 
            </div>
        
            <div className="img-szczecin">
                <img src={sow5} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={sow8} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={sow9} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={sow4} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={sow7} alt="Item 1" /> 
            </div>

            </Carousel> 
        </Row>
        </Container>

        <Row>

            <div className="img-szczecin">
            <div className="box">
                    <h3>Apartament 4K Nautica</h3>
                    <p className="adress">Majora Władysława Raginisa 23/21,<br></br> Śródmieście, 71-625 Szczecin </p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● prywatny parking </p>
                    <p className="point"> ● WIFI </p>
                    <p className="pointa"> ● zwierzęta domowe są akceptowane </p>
                    <a href="https://www.booking.com/hotel/pl/apartament-4k-nautica-szczecin.pl.html" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a>
                </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={naut2} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={naut1} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={naut3} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={naut4} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={naut5} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={naut6} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={naut7} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={naut8} alt="Item 1" /> 
            </div>

            </Carousel> 
        </Row>

        <Row>

            <div className="img-szczecin">
                <div className="box">
                    <h3>Apartament 5K Nautica</h3>
                    <p className="adress">Majora Władysława Raginisa 21/34,<br></br> Śródmieście, 71-625 Szczecin </p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● parking ogólnodostępny </p>
                    <p className="point"> ● WIFI </p>
                    <p className="pointa"> ● zwierzęta domowe są akceptowane </p>
                    <a href="https://www.booking.com/hotel/pl/apartament-5k-nautica-szczecin.pl.html?aid=318615&label=New_Polish_PL_PL_27026340625-8jnhBl9f%2ARWlwi1GFE5ASAS637942120544%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg%3Afi%3Atidsa-140566009945%3Alp20862%3Ali%3Adec%3Adm%3Aag27026340625%3Acmp400536505&sid=cf797595966fbc21a6acb111e5a6dcba&dest_id=-531881;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1703681263;srpvid=96d859e8274b01ba;type=total;ucfs=1&#hotelTmpl" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a>
                </div>
            </div>
             <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={nautica1} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={nautica3} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={nautica5} alt="Item 1" /> 
            </div> 
            <div className="img-szczecin">
                <img src={nautica6} alt="Item 1" /> 
            </div> 
            <div className="img-szczecin">
                <img src={nautica7} alt="Item 1" /> 
            </div> 

            <div className="img-szczecin">
                <img src={nautica8} alt="Item 1" /> 
            </div> 

            </Carousel> 

        </Row>

        <Row>
            <div className="img-szczecin">
                <div className="box">
                    <h3>Apartament 8B  Wały Chrobrego </h3>
                    <p className="adress">Szarotki 22/15, Śródmieście,<br></br>71-604 Szczecin, Polska </p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● prywatny parking podziemny </p>
                    <p className="point"> ● WIFI </p>
                    <p className="pointa"> ● zwierzęta domowe są akceptowane </p>
                    <a href="https://www.booking.com/hotel/pl/apartament-8b-waly-chrobrego-szczecin.pl.html?aid=356980&label=gog235jc-1DCAsotgFCJWFwYXJ0YW1lbnQtOGItd2FseS1jaHJvYnJlZ28tc3pjemVjaW5IM1gDaLYBiAEBmAEeuAEHyAEN2AED6AEBiAIBqAIDuALGurCsBsACAdICJGFiMjQ4MDY4LWRmNzctNDZmZS1iMTRjLTIzNGE0OWFhOGVjM9gCBOACAQ&sid=cf797595966fbc21a6acb111e5a6dcba&dist=0&group_adults=2&group_children=0&keep_landing=1&no_rooms=1&sb_price_type=total&type=total&#map_closed" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a>
                </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={szarotki2} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki1} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki3} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki4} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki5} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki6} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={szarotki7} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={szarotki8} alt="Item 1" /> 
            </div>
            </Carousel> 
        </Row>

        <Row>
            <div className="img-szczecin">
                <div className="box">
                    <h3>Apartament 9B Wały Chrobrego </h3>
                    <p className="adress">Szarotki 22/15, Śródmieście,<br></br>71-604 Szczecin, Polska </p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● prywatny parking podziemny </p>
                    <p className="point"> ● WIFI </p>
                    <p className="pointa"> ● zwierzęta domowe są akceptowane </p>
                    <a href="https://www.booking.com/hotel/pl/apartament-9b-waly-chrobrego-szczecin.pl.html" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a>
                </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={szarotki1} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki2} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki3} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki4} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki5} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={szarotki6} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={szarotki8} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={szarotki7} alt="Item 1" /> 
            </div>
            </Carousel>

        </Row> 

        <Row>
            <div className="img-szczecin">
                <div className="box">
                    <h3>Apartament 1E Barbakan </h3>
                    <p className="adress">Barbakan 6/8,<br></br>71-028 Szczecin, Polska</p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● parking ogólnodostępny </p>
                    <p className="point"> ● WIFI </p>
                    <p className="pointa"> ● zwierzęta domowe są akceptowane </p>
                    <a href="https://www.booking.com/hotel/pl/apartament-1e-barbakan-szczecin-szczecin.pl.html?aid=1263239&label=PShare-Pulse-3C8Z4K%401720978859&sid=abb8644e4a3f8ac6c1f45ff07c523e42&dist=0&group_adults=2&group_children=0&keep_landing=1&no_rooms=1&sb_price_type=total&type=total&activeTab=main" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a>
                </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={bar1} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={bar2} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={bar3} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={bar4} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={bar4a} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={bar4b} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={bar5} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={bar6} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={bar7} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={bar8} alt="Item 1" /> 
            </div>
            </Carousel>

        </Row> 


        <div className="apartments-rewal" id='apartments-rewal'>
        <h2>Apartamenty w Rewalu</h2> 
        <h3> Rezerwacja poprzez Booking.com lub bezpośrednio (zniżka 5% od ceny z Booking.com)</h3>
        <h5>biuro@klepaccy.pl</h5>
        <h4>+48 601 76 26 07</h4>
        
        </div>

        <Row>
            <div className="img-szczecin">
                <div className="box">
                <h3>Apartament 6M Klifowa</h3>
                    <p className="adress">Morska 7/18,<br></br>72-344 Rewal, Polska</p>
                    <p className="point"> ● Apartament 2 pokojowy (max 4 osoby)</p>
                    <p className="point"> ● Basen, Sauny, Siłownia, Sala zabaw dla dzieci </p>
                    <p className="point"> ● Plaża 100m </p>
                    <p className="point"> ● Prywatny parking</p>
                    <p className="pointa"> ● WIFI</p>
                    <a href="https://www.booking.com/hotel/pl/apartament-6m-klifowa-rewal-w-cenie-basen-jacuzzi-silownia-sala-zabaw.pl.html" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a> </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={rew7} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rew1} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rew2} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rew3} alt="Item 1" /> 
            </div>
        
            <div className="img-szczecin">
                <img src={rew4} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={rew5} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rew6} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={rew11} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rew12} alt="Item 1" /> 
            </div>
        
            <div className="img-szczecin">
                <img src={rew10} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={rew8} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rew9} alt="Item 1" /> 
            </div>
    

            </Carousel> 
        </Row>


        <Row>
            <div className="img-szczecin">
                <div className="box">
                <h3>Apartament 7M Klifowa</h3>
                <h5> (basen w jednym budynku)</h5>
                    <p className="adress">Morska 8/26,<br></br>72-344 Rewal, Polska</p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● Basen, Sauny, Siłownia, Sala zabaw dla dzieci </p>
                    <p className="point"> ● Plaża 100m </p>
                    <p className="point"> ● Prywatny parking</p>
                    <p className="pointa"> ● WIFI</p>
                    <a href="https://www.booking.com/hotel/pl/apartament-7m-z-basenem-klifowa-rewal.pl.html" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a> </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={rewal1} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rewal8} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rewal3} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rewal4} alt="Item 1" /> 
            </div>
        
            <div className="img-szczecin">
                <img src={rewal5} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={rewal6} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rewal7} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rewal2} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={rewal9} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={rewal10} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={rewal12} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={rewal11} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={rewal13} alt="Item 1" /> 
            </div>

            </Carousel> 
        </Row>


        <div className="apartments-wroc" id='apartments-wroc'>
        <h2>Apartamenty we Wrocławiu</h2> 
        <h3> Rezerwacja poprzez Booking.com lub bezpośrednio (zniżka 5% od ceny z Booking.com)</h3>
        <h5>biuro@klepaccy.pl</h5>
        <h4>+48 601 76 26 07</h4>
        
        </div>

        <Row>
            <div className="img-szczecin">
                <div className="box">
                <h3>Apartament 10W Ostrów Tumski Wrocław </h3>
                    <p className="adress">Bolesława Prusa 8 22, Śródmieście,<br></br>50-319 Wrocław, Polska</p>
                    <p className="point"> ● Apartament 2 pokojowy (max 6 osób)</p>
                    <p className="point"> ● Prywatny parking</p>
                    <p className="pointa"> ● WIFI</p>
                    <p className="pointa"> ● zwierzęta domowe są akceptowane</p>
                    <a href="https://www.booking.com/hotel/pl/apartament-10w-ostrow-tumski-wroclaw.pl.html" >
                    <button className="vvd"><span>ZAREZERWUJ</span></button>
                    </a> </div>
            </div>
            <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
            <div className="img-szczecin">
                <img src={wro1} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={wro12} alt="Item 1" /> 
            </div>
            <div className="img-szczecin">
                <img src={wro11} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={wro9} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={wro8} alt="Item 1" /> 
            </div>
        
            <div className="img-szczecin">
                <img src={wro5} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={wro2} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={wro3} alt="Item 1" /> 
            </div>

            <div className="img-szczecin">
                <img src={wro6} alt="Item 1" /> 
            </div>


            <div className="img-szczecin">
                <img src={wro7} alt="Item 1" /> 
            </div>
        
            <div className="img-szczecin">
                <img src={wro10} alt="Item 1" /> 
            </div>
            
            <div className="img-szczecin">
                <img src={wro4} alt="Item 1" /> 
            </div>
            
            </Carousel> 
        </Row>
        
    </Container>
  </section>
  )
}