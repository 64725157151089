import { useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown, Row} from "react-bootstrap";
import React from "react";
import logo from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/logo.svg';
import { 
  BrowserRouter as Router
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

export const NavBar = () => {

  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

    return (
      <Router id='home'> 
      <section id="home">
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
          <Container>
            <Navbar.Brand href="/">
            <img src={logo} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
            <Navbar.Collapse id="basic-navbar-nav" >
              <Nav className="/">
                <Nav.Link href="#home" className={activeLink === 'home'?'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('navbar')}>Home</Nav.Link>
                <Nav.Link href="#about" className={activeLink === 'about'?'active navbar-link':'navbar-link'} onClick={() => onUpdateActiveLink('about')}>O nas</Nav.Link>
                  <NavDropdown className="drop" title="Apartamenty" id="basic-nav-dropdown">
                    <NavDropdown.Item className="drop-town" href="#apartments-szczecin">Apartamenty w Szczecinie</NavDropdown.Item>
                    <NavDropdown.Item className="drop-town" href="#apartments-rewal">Apartamenty w Rewalu</NavDropdown.Item>
                    <NavDropdown.Item className="drop-town" href="#apartments-wroc">Apartamenty we Wrocławiu</NavDropdown.Item>
                  </NavDropdown>
                <Nav.Link href="#footer" className={activeLink === 'footer'?'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('footer')}>Kontakt</Nav.Link>

                <span className="navbar-text">
              <HashLink to='#apartments'>
                <button className="vvd"><span>ZAREZERWUJ</span></button>
              </HashLink></span>
          
              </Nav>
            </Navbar.Collapse>
          </Container>
         </Navbar>
         </section>
      </Router>
     
    )
}
