import { Container, Row, Col } from "react-bootstrap";
import React from "react";
import logo from '/Users/zuzannaklepacka/Desktop/apartszcz/apartszcz/src/assets/img/apart/logo.svg';

export const Footer = () => {
  return (
    <section className="footer" id="footer">
    <div className="footer">
      

      <div className="info w-full max-w-4xl flex flex-wrap justify-between mt-6 px-6 text-center md:text-left">
        <div className="column w-full md:w-1/3 mb-4 md:mb-0">
          <h3 className="text-lg font-semibold border-b-2 pb-1 mb-3">Kontakt</h3>
          <p>Telefon: +48 601 76 26 07</p>
          <p>Email: biuro@klepaccy.pl</p>
          <p>Kontakt całodobowy 24/7</p>
        </div>

        <div className="logo flex flex-col items-center">
        <img src={logo} alt="Logo" className="w-20 mb-2" />
        <h4>Copyright 2023. All Rights Reserved</h4>
      </div>
      
        <div className="column w-full md:w-1/3 mb-4 md:mb-0">
          <h3 className="text-lg font-semibold border-b-2 pb-1 mb-3">Dane</h3>
          <p>COMMTEL Mariusz Klepacki</p>
          <p>Adres: ul. Ustki 6/14, 70-831 Szczecin</p>
          <p>NIP: 955-125-48-47</p>
          <p>Rachunek bankowy: 40 1020 4795 0000 9902 0005 4239</p>
        </div>
       
        </div>
      </div>
      </section>
  );
};
